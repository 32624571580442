$size-large: 992px;

section {
	font-size: 1.2em;

	h2 {
		text-transform: none !important;
		font-size: 2.0em;
		margin-bottom: 25px;
		font-weight: normal;
		color: black;
	}

	h3 {
		font-weight: normal;
		color: black;
	}

	img,video {
		max-width: 100%;
	}

	&.dark {
		background-color: #f2f4f8;
	}

	&.with-space-top {
		margin-top: 50px;
	}

	&.with-space-bottom {
		margin-bottom: 50px;
	}

	&.with-space {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.narrow-text-75 {
		@media (min-width: $size-large) {
			padding-left: 75px;
			padding-right: 75px;
		}
	}
}

.feature-list {
	.feature {
		.navy-line {
			margin: 0;
		}
	}

	.row {
		&.with-space {
			@media (min-width: $size-large) {
				margin-top: 35px;
			}
		}
	}
}

.navy-line-left {
	margin: 0;
}

.icon-large {
	font-size: 96px;
}

.payg-rates {
	margin-top: 30px;

	h2 {
		text-align: center;
	}

	.payg-rates-primary {
		font-size: 1.6em !important;

		span {
			font-size: 1.5em;
			color: #1ab394;
		}
	}

	span {
		font-weight: bold;
	}
}

.footnote {
	font-size: 0.85em !important;
	font-style: italic;
	margin-top: 45px;
}

.patchkit-in-numbers {
	p {
		max-width: 400px;
		margin: 0 auto;
	}

	span {
		font-size: 1.2em;
		color: #1ab394;
	}
}

.section-report-abuse {
	.form-group {
		margin-bottom: 20px;
	}

	textarea {
		resize: vertical;
	}
}

section.learning-resources {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.learning-resources {

	.resources-container {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		gap: 1rem;
		padding: 1rem 0;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: thin;
		
		&::-webkit-scrollbar {
			height: 6px;
		}
		
		&::-webkit-scrollbar-track {
			background: #f1f1f1;
		}
		
		&::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 3px;
		}
	}

	.resource-card {
		flex: 0 0 auto;
		width: 350px;
		cursor: pointer;
		.card {
			border: 1px solid rgba(0,0,0,.125);
			border-radius: 8px;
			transition: transform 0.2s ease-in-out;
			background-color: white;

			box-shadow: 0 4px 8px rgba(0,0,0,0.1);
			
			&:hover {
				transform: translateY(-5px);
			}

			display: flex;
			flex-direction: column;
			
		}
		
		.card-img-container {
			height: 200px;
			overflow: hidden;
			
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
			}
		}
		
		.card-body {
			padding: 1.25rem;
			flex: 1 1 auto;
		}
		
		.card-title {
			font-size: 2rem;
			margin-bottom: 0.75rem;
			line-height: 1.4;
		}
		
		.tags {
			display: flex;
			flex-wrap: wrap;
			gap: 0.5rem;
			margin-top: 0.5rem;
			margin-left: 0.5rem;
			margin-bottom: 0.5rem;
			margin-right: 0.5rem;
			
			.badge {
				font-size: 1.5rem;
				font-weight: 500;
				padding: 0.35em 0.65em;
				text-decoration: none;
				
				&:hover {
					opacity: 0.9;
				}
			}
		}
		
		.card-footer {
			padding: 0.75rem 1.25rem;
			border-top: 1px solid rgba(0,0,0,.125);
			font-size: 1.5rem;
		}
	}
} 